<template>
  <router-view></router-view>
</template>
<script>
export default {
  mounted() {
    if (!window.sessionStorage.getItem('client')) {
      if (this.isMobile() == 'mobile') {
        this.$router.replace('/')
      } else {
        this.$router.replace('/pcindex')
      }
    }
    window.sessionStorage.setItem('client', this.isMobile())
  },
  methods: {
    isMobile() {
      var userAgent = navigator.userAgent || navigator.vendor || window.opera
      // 检测常见的移动设备标识
      if (/android/i.test(userAgent)) {
        return 'mobile'
      }
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return 'mobile'
      }
      return 'pc'
    },
  },
}
</script>

<style lang="less">
.el-button--primary,
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #405363 !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: var(--font-icon-t3);
  background: transparent;
  background-color: #353b45;
}

::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background: var(--font-icon-t3);
}

::-webkit-scrollbar-track {
  background: var(--el-color-white);
}
</style>
