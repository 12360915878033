export default {
    common: {
        send: "Dispatch",
        submit: "Submit",
        login: "Login",
        register: "Enrollment",
        message: "The materials have been submitted, please pay attention to your email",
        no_more: "no data...",
        loading: "loading...",
        search: "search",
        select1: "Choose a country",
        select2: "Choose a type",
    },
    header: {
        home: "Homepage",
        about_us: "About Us",
        hot_project: "Hot Items",
        attacking_strategy: "Immigration Guide",
        consult_immediately: "Enquire Now",
    },
    footer: {
        precision_immigration: "Precision Migration",
        contact_us: "Contact Us",
        usa: "USA",
    },
    about: {
        p1: "About Target Immigration BBS NewYork LLC",
        p2: "Target Immigration, one of the most recognized professional organizations in the immigration industry, was founded by a number of industry veterans, founded in Hong Kong in 2013, and relocated its headquarters to Fifth Avenue in Manhattan, New York in early 2022, adjacent to the Empire State Building.",
        p3: "Build reputation with strength",
        p4: "Each case of Precision Immigration is subject to a strict triple-examination system, whereby the senior copywriter collects the data, the project director conducts the first examination, the foreign lawyer conducts the second examination, and the ex-immigration officer conducts the final examination of the case before submission, so as to ensure the success rate of each VIP customer!",
        p5: "In addition to traditional immigration consultancy services, Precision Immigration also provides the industry's unique VIP Hong Kong secretarial services, VIP passport safe deposit box services, VIP global bank account opening services and VIP global travel services and so on.",
        p6: "Precision Immigration, a reliable “one-stop immigration solution” provider, your partner in global affairs.",
        p7: "Get in touch so we can start working together.",

    },
    form: {
        label_first_name: "First Name",
        place_first_name: "Please enter First Name",
        label_last_name: "Last Name",
        place_last_name: "Please enter Last Name",
        label_email: "Email",
        place_email: "Please enter Email",
        label_message: "Message",
        place_message: "Please enter Message",
        place_search: "Please enter your search",
    },
    contact: {
        contact_p1: "Let a professional consultant customize an immigration plan for you.",
        new_york: "NYC",
        phone: "Telephones",
    },
    home: {
        h1: "For uncertain times,",
        h2: "we provide the utmost certainty!",
        h3: "Contact our team of professionals for a customized",
        h4: "Migration Program",
        learn_more: "Learn more",
        more_msg: "More information",
        p1: "Precision Immigration, a reliable “one-stop immigration solution” provider, your partner in global affairs!",
        consultation_now: "Enquire Now",
        immigration_strategy: "Immigration Tips",
        p2: "Contact our team of professionals to customize your immigration solution",
        target: "Goal",
        seek_advice: "Consultancy",
    },
    hot: {
        p1: "25 countries, a variety of immigration programs, covering Europe, the United States, Asia, Oceania Contact a professional consultant immediately, free customized program!",
        nav1: "European",
        nav2: "North America",
        nav3: "Asian",
        nav4: "Oceania",
        learn_more: "Learn more",
        service_charge: "Service fee",
        processing_cycle: "Processing cycle",
        month: "Months",
        investment_quota: "Investment level",
        identity_type: "Type of identity",
        residence_visa: "Resident visa",
        residency_requirement: "Residence requirements",
        stay_per_year: "7 days per year",
        cont1: "Portugal's Immigrant Investor Program (ARI) is known as Portugal's “Golden Visa”. Anyone who buys a property worth 500,000 euros in Portugal; or a property worth 350,000 euros that is more than 30 years old can apply for a Portuguese Residence Visa. Residence for 5 years will be able to apply for permanent residency, and then in 1 year through the Portuguese language examination, you can apply for naturalization. The approval threshold is low, the property can be rented out or self-occupied, only need to live in Portugal for 7 days per year, one person applies, the whole family of three generations of immigrants.",
        cont2: "Requirements for application",
        cont3: "Main applicant",
        cont4: "1. At least 18 years of age",
        cont5: "2. Clean bill of health",
        cont6: "3. Non-EU residents",
        cont7: "4. Complete one of the following investments",
        cont8: "a. Investing in a €350,000 fund；",
        cont9: "b. Investment of €350,000 in a property over 30 years old in Portugal.; ",
        cont10: "c. Invest €500,000 in any property in Portugal.",
        cont11: "Application Process",
        cont12: "1.Payment for Precision Immigration Counseling Service (1 day)",
        cont13: "2.Preparation of information and production of application materials (1-2 months)",
        cont14: "3.Study tour to Portugal for home ownership (3-4 months)",
        cont15: "4.Application for residency (1 year) (8-10 months)",
        cont16: "5.Obtaining a residence card (1-2 months)",
        cont17: "6.Renewal of residence card (1 year)",
        cont18: "7.Permanent resident status can be applied for after 5 years of holding a residence card and residing in Portugal for more than 7 days per year (5 years).",
        cont19: "8.Become a permanent resident for 1 year, pass the Portuguese language exam and apply for naturalization (1 year)",
        cont20: "Green Card/Long Term Visa",
        cont21: "Passport",
    },
    strategy: {
        read: "Read",
        minute: "Minutes",
        ci: "Vice-",
        view: "Ferret out",
        ze: "Imitate",
        message: "Leave a message",
        place_message: "Write a message",
    },


};
