export default {
    common: {
        send: "发送",
        submit: "提交",
        login: "登录",
        register: "注册",
        message: "资料已提交，请注意邮箱",
        no_more: "没有更多数据..",
        loading: "加载中...",
        search: "搜索",
        select1: "请选择国家",
        select2: "请选择类型",
    },
    header: {
        home: "主页",
        about_us: "关于我们",
        hot_project: "热门项目",
        attacking_strategy: "移民攻略",
        consult_immediately: "立即咨询",
    },
    footer: {
        precision_immigration: "精准移民",
        contact_us: "联系我们",
        usa: "美国",
    },
    about: {
        p1: "关于Target精准移民 BBS NewYork LLC",
        p2: "精准移民是移民行业的领先机构，2013年创立于香港，2017年于纽约设立总部，其后在迪拜和新加坡设立分支机构。纽约总部位于曼哈顿第五大道358号，紧邻帝国大厦.",
        p3: "以实力打造口碑",
        p4: "精准移民的每一个案子都实行严格的三审制度，由资深文案收集资料，项目总监一审，外籍律师二审，前移民官于递交前最终审案，保证每一位VIP客户的成功率。！",
        p5: "除了传统移民顾问服务外，精准移民还提供全行业独一无二的VIP香港秘书服务、VIP护照保管箱服务、VIP全球银行开户服务以及VIP全球出行服务等等。",
        p6: "精准移民，值得信赖的「一站式移民解决方案」提供商，您的全球事务协作伙伴",
        p7: "Get in touch so we can start working together.",

    },
    form: {
        label_first_name: "名",
        place_first_name: "请输入名字",
        label_last_name: "姓",
        place_last_name: "请输入姓氏",
        label_email: "Email",
        place_email: "请输入电子邮件",
        label_message: "留言",
        place_message: "请输入留言",
        place_search: "请输入搜索内容",
    },
    contact: {
        contact_p1: "让专业顾问为您订制专属移民方案",
        new_york: "纽约",
        phone: "电话",
    },
    home: {
        h1: "给不确定的时代，",
        h2: "提供最大的确定性！",
        h3: "联络我们的专业团队，为您定制",
        h4: "移民方案",
        learn_more: "了解详情",
        more_msg: "更多信息",
        p1: "精准移民，值得信赖的「一站式移民解决方案」提供商，您的全球事务协作伙伴！",
        consultation_now: "现在咨询",
        immigration_strategy: "移民攻略",
        p2: "联络我们的专业团队，为您定制移民方案",
        target: "目标",
        seek_advice: "咨询",
    },
    hot: {
        p1: "25个国家,多种移民方案, 涵盖欧、美、亚、大洋洲立即联络专业顾问，免费订制专属方案",
        nav1: "欧洲",
        nav2: "北美",
        nav3: "亚洲",
        nav4: "大洋洲",
        learn_more: "了解更多",
        service_charge: "服务费",
        processing_cycle: "办理周期",
        month: "个月",
        investment_quota: "投资额度",
        identity_type: "身份类型",
        residence_visa: "居留签证",
        residency_requirement: "居住要求",
        stay_per_year: "每年逗留7天",
        cont1: "葡萄牙投资移民计划（ARI）被喻为葡萄牙「黄金签证」，凡于葡萄牙购买价值50万欧元的房产；或价值35万欧元，楼龄超过30年以上的房产，便可申请葡萄牙居留签证。居满5年便可申请永久居留权，再于1年通过葡语考试，可申请入籍。审批门坎低，物业可出租可自住，每年只需在葡萄牙居满7天便可，一人申请，全家三代移民。",
        cont2: "申请条件",
        cont3: "主申请人",
        cont4: "1. 年满18周岁",
        cont5: "2. 无犯罪记录",
        cont6: "3. 非歐盟居民",
        cont7: "4. 完成以下其中一項投資",
        cont8: "a. 投資35萬歐元基金；",
        cont9: "b. 投資35萬歐元在葡萄牙購買樓齡超過30年以上的房產; ",
        cont10: "c. 投資50萬歐元在葡萄牙購買任何房產",
        cont11: "申請流程",
        cont12: "1.缴纳精准移民咨询服务费（1天）",
        cont13: "2.准备资料，制作申请材料（1-2个月）",
        cont14: "3.赴葡萄牙考察置业（3-4个月）",
        cont15: "4.申请居留权（1年期）（8-10个月）",
        cont16: "5.获得居留卡（1-2个月）",
        cont17: "6. 居留卡续期（1年）",
        cont18: "7. 持居留卡满5年，每年在葡萄牙居住7天以上，可申请永久居民身份　（5年）",
        cont19: "8. 成为永久居民满1年，通过葡语考试，申请入籍（1年）",
        cont20: "绿卡/长期签证",
        cont21: "护照",
    },
    strategy: {
        read: "已讀",
        minute: "分鐘",
        ci: "次",
        view: "查看",
        ze: "则",
        message: "留言",
        place_message: "撰写留言",
    },


};
