import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';//使用
Vue.use(Element);
// 导入全局样式表
import './assets/css/global.css'
import './assets/css/main.css'
// 导入iconfont字体库
import '../static/font/iconfont/iconfont.css'
import Vant from 'vant';
import 'vant/lib/index.css';
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
// 导入多语言文件
import i18n from "./tools/i18n.js";
import lang from "./tools/lang.js";
window.lang = lang;

import VueClipBoard from 'vue-clipboard2'
Vue.use(VueAwesomeSwiper)
Vue.use(VueClipBoard)
Vue.use(Vant)
Vue.config.productionTip = false

import * as filters from './filters' // global filters
// import '@/permission' // permission control

Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
